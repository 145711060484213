<template>
  <div class="relative cursor-pointer" @click="action()">
    <div v-show="!isGeneral" class="absolute z-10 bottom-1 right-3 opacity-30">
      <Quotes width="32" height="32" color="yellow" />
    </div>
    <div class="flex justify-center mt-px">
      <div class="w-20 h-20 -mb-10 relative z-10 with-outline rounded-full bg-white flex justify-center items-center">
        <ImageItem :source="item.testimony.userPhoto" imageClass="rounded-full h-20 w-20" :alternateText="'testimony-photo-' + index" v-if="item?.testimony?.userPhoto" />
        <span v-else class="font-bold flex w-full h-full justify-center items-center bg-white rounded-full">
          <User color="neutral-300" width="80" height="80" />
        </span>
      </div>
    </div>
    <div class="flex message" :id="'testimony-' + index">
      <div class="rounded-lg px-8 pb-6 pt-12 bg-white relative mdmax:px-4 w-full" :class="{ 'border border-grey-field': !borderless }">
        <div class="flex items-center gap-4 flex-col">
          <div class="text-center mb-3">
            <div class="text-base font-bold leading-5 mb-1 line-clamp" v-html="item?.testimony?.userName"></div>
            <div
              class="text-xs h-4 text-yellow leading-4 font-semibold"
              :class="[item?.testimony?.placement && isGeneral ? 'line-clamp-placement' : !isGeneral ? '' : 'hidden']"
              v-html="item?.testimony?.placement"
            ></div>
          </div>
        </div>
        <div class="">
          <div class="text-left text-sm leading-6" :class="{ 'line-clamp-testimony': !full }" v-html="item?.testimony?.message"></div>
          <div v-show="programName" class="text-xs text-neutral-300 mt-3 font-medium text-center">
            {{ programName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    Quotes: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Quotes'),
    User: () => import(/* webpackChunkName: "icon" */ '@/components/icons/PersonCircle')
  },
  props: {
    programName: {
      type: String,
      default: null
    },
    item: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: 0
    },
    full: {
      type: Boolean,
      default: false
    },
    isGeneral: {
      type: Boolean,
      default: false
    },
    borderless: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    action() {
      this.$emit('action', this.item)
    },
    getName(name) {
      const arr = name.split(' ')
      const newArr = []
      newArr.push(arr[0].slice(0, 1).toUpperCase())
      if (arr.length > 1) newArr.push(arr[1].slice(0, 1).toUpperCase())
      return newArr.join('')
    }
  }
}
</script>
<style lang="scss" scoped>
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.line-clamp-placement {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
}
.line-clamp-testimony {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  // word-break: break-all;
  height: 100px;
}
.message {
  height: calc(100% - 41px);
}
.with-outline {
  border: 1px solid #d6d6d6;
}
</style>
